body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .check-header {
    @apply text-center text-white cursor-pointer font-bold
  }

  .check-header-scroll {
    @apply text-center text-[#003177] cursor-pointer font-bold
  }

  .box-shadow {
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
  }

  .border-radius-tl-br {
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .box-shadow-1 {
    box-shadow: 10px 8px 30px 5px #FFDEB2;
  }

  .box-shadow-2 {
    box-shadow: -30px 30px 15px #C0CFED;
  }

  .box-shadow-3 {
    box-shadow: 5px 5px 8px #999;
  }
}